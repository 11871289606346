import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import CenteredPage from '../components/CenteredPage';
import LoadingError from '../components/LoadingError';
import Button from '../components/Button';
import FlexRow, { FlexPadding } from '../components/FlexRow';
import StreamList from '../components/StreamList';
import { GET_USER } from '../gql/query';
import { DELETE_STREAM } from '../gql/mutation';

const User = (props) => {
  const userId = props.match.params.id;
  const { data, loading, error } = useQuery(GET_USER, {
    variables: {
      id: userId,
    }}
  );
  const [ deleteStream, {dLoading} ] = useMutation(DELETE_STREAM, {
    refetchQueries: [{ 
      query: GET_USER,
      variables: { id: userId }
    }]
  });


  const handleViewStream = (e, stream) => {
    e.preventDefault();
    // Throw the subscription token into local storage
    localStorage.setItem('SubToken', stream.subToken);
    // Go to the stream
    props.history.push(`/stream/${stream.controlRoom.streamName}`);

  }

  const handleDelete = (e, stream) => {
    deleteStream({
      variables: {
        id: stream._id,
      }
    })
  }


  if (loading || error || dLoading) {
    return (
      <LoadingError loading={loading || dLoading} error={error} />
    )
  }

  return( 
    <CenteredPage>
      {/*console.log(data)*/}
      <h2>User:{` ${data.user.username}`}</h2>
      <StreamList 
        streams={data.user.streams} 
        handleTrClick={handleViewStream}
        showDelete={true}
        handleDelete={handleDelete}
      />
      <FlexRow>
        <FlexPadding>
          <Button>
            Delete User
          </Button>
        </FlexPadding>
        <FlexPadding>
          <Button 
            onClick={(e) => 
            props.history.push(`/streamcreate/${userId}`)
            }>
            Add Stream Subscription
          </Button>
        </FlexPadding>
      </FlexRow>
    </CenteredPage>
  )
};

export default User;
