import { gql } from '@apollo/client';

const ME = gql`
  query me {
    me {
      _id
      username
      roll
      streams {
        _id
        controlRoom {
          _id
          name
          streamName
        }
        tokenId
        subToken
        addedOn
        expiresOn
      }
    }
  }
`;

const GET_USERS = gql`
  query GET_USERS {
    users {
      _id
      username
      roll
      streams {
        controlRoom {
          _id
          name
          streamName
        }
        subToken
        expiresOn
        addedOn
      }
    }
  }
`;

const GET_USER = gql`
  query user($id: ID!) {
    user(_id: $id ){
      _id
      username
      roll
      streams {
        _id
        controlRoom {
          _id
          name
          streamName
        }
        tokenId
        subToken
        expiresOn
        addedOn
      }
    }
  }

`;

const GET_CONTROL_ROOMS = gql`
  query controlRooms {
    controlRooms {
      _id
      name
      streamName
    }
  }
`;

const GET_CONTROL_ROOM = gql`
  query controlRoom ($id: ID!) {
    controlRoom(_id: $id) {
      _id
      name
      streamName
    }
  }
`;


export { 
  ME,
  GET_USER,
  GET_USERS,
  GET_CONTROL_ROOM,
  GET_CONTROL_ROOMS,
};
