import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import CenteredPage from '../components/CenteredPage';
import FormColumn from '../components/FormColumn';
import Button from '../components/Button';
import LoadingError from '../components/LoadingError';
import Dropdown from '../components/Dropdown';
import FlexRow, { FlexPadding } from '../components/FlexRow';

import { GET_CONTROL_ROOMS, GET_USER } from '../gql/query';
import { CREATE_STREAM } from '../gql/mutation';


const StreamCreate = (props) => {
  const userId = props.match.params.id;
  const [ values, setValues ] = useState();

  const { data, loading, error } = useQuery(GET_CONTROL_ROOMS);
  const [ createStream ] = useMutation(CREATE_STREAM, {
    refetchQueries: [{ 
      query: GET_USER,
      variables: { id: userId }
    }],
    onCompleted: data => {
      props.history.goBack();
    },
  });
 
  
  const onChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  }

  if (loading || error) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  return( 
    <CenteredPage>
      {/*console.log(data)*/}
      <h2>Create Stream Subscription</h2>
      <h3>{props.match.params.id}</h3>
      <FormColumn
        onSubmit={ e => {
          e.preventDefault();

          let expiration = null;

          if (values.expiration) {
            expiration = parseInt(values.expiration);
          } 

          createStream({
            variables: {
              controlRoom_id: values.controlRoom_id,
              user_id: userId,
              expiration: expiration
            }
          })}}>
        <Dropdown 
          title={"Select Control Room"}
          options={data.controlRooms}
          name="controlRoom_id"
          onChange={onChange}
        />
        <label htmlFor="Expiration (TTL)">Expiration (TTL):</label>
        <input
          type="number"
          id="expiration"
          name="expiration"
          placeholder="24000"
          onChange={onChange}
        />
        <FlexRow>
          <FlexPadding>
            <Button 
              type='reset'
              onClick={() => props.history.goBack()}
            >Cancel</Button>
          </FlexPadding>
          <FlexPadding>
            <Button type="submit">Submit</Button>
          </FlexPadding>
        </FlexRow>
      </FormColumn>
    </CenteredPage>
  )
};

export default StreamCreate;
