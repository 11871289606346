import { createGlobalStyle } from 'styled-components';
import normalize from '@csstools/normalize.css';

export default createGlobalStyle`
  ${normalize}

  html,
  body {
    padding: 0;
    margin: 0;
    color: #fff;
    background: #111111;
    line-height: 1.2;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  p,
  h1 {
    padding: 5px;
    margin: 5px;
  }

  h2,
  h3 {
    padding: 5px;
    margin: 10px;
  }

  a {
    padding: 5px;
    margin: 5px;
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  a:hover {
    color: #A5A5A5;
  }

  *:focus {
    outline: none;
  }

`;

