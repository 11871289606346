import React , { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import {
  login,
  selectLogin
} from '../redux/loginSlice';


import CenteredPage from '../components/CenteredPage';
import FormColumn from '../components/FormColumn';
import Button from '../components/Button';
import { SIGN_IN } from '../gql/mutation';

const Root = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogin);
  const [ values, setValues ] = useState();
  const [ signIn, { loading } ] = useMutation(SIGN_IN, {
    onCompleted: data => {
      console.log("Signed In!");
      console.log(data);

      dispatch(login(data.signIn));
      props.history.push("/home");
    },
    onError: (err) => {
      console.log(err);
    }

  });

  // Do we check the roll too??? Nah...the redux
  // initial state checks it.
  useEffect(() => {
    if (isLoggedIn) {
      props.history.push('/home');
    }
  },[]);

  const onChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  if (loading) {
    return (
      <CenteredPage>
        <h1>Loading ...</h1>
      </CenteredPage>
    )
  }


  return (
    <CenteredPage>
      <FormColumn onSubmit={ (e) => {
        e.preventDefault();
        signIn({
          variables: {
            ...values
          }
        })}}>
        <label htmlFor="username">Username:</label>
        <input
          required
          type="text"
          id="username"
          name="username"
          placeholder="username"
          onChange={onChange}
        />
        <label htmlFor="password">Password:</label>
        <input
          required
          type="password"
          id="password"
          name="password"
          placeholder="password"
          onChange={onChange}
        />
        <Button type="submit">Submit</Button>
      </FormColumn>

    </CenteredPage>

  );
}

export default Root;
