import styled from 'styled-components';

const FormColumn = styled.form`
  display: flex;
  flex-direction: column;

  label,
  input {
    line-height: 2em;
  }

  input {
    width: 100%;
    margin-bottom: 1em;
  }

`;

export default FormColumn;
