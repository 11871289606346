import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { useSelector } from 'react-redux';
import {
  selectRoll
} from '../redux/loginSlice';


import { ME } from '../gql/query';
import { DELETE_STREAM } from '../gql/mutation';

import Button from '../components/Button';
import CenteredPage from '../components/CenteredPage';
import FlexRow, { FlexPadding } from '../components/FlexRow';
import LoadingError from '../components/LoadingError';
import StreamList from '../components/StreamList';

const Home = (props) => {
  const { data, error, loading } = useQuery(ME, {
    fetchPolicy: "network-only"
  });
  const [ deleteStream, {dLoading} ] = useMutation(DELETE_STREAM, {
    refetchQueries: [{ 
      query: ME,
    }], 
  });
  const roll = useSelector(selectRoll);

 
  const handleViewStream = (e, stream) => {
    e.preventDefault();

    localStorage.setItem('SubToken', stream.subToken);

    props.history.push(`/stream/${stream.controlRoom.streamName}`);

  }

  const handleDelete = (e, stream) => {
    deleteStream({
      variables: {
        id: stream._id,
      }
    })
  }

  if (loading || error || dLoading) {
    return(
      <LoadingError loading={loading || dLoading} error={error} />
    )
  }

  return(
    <CenteredPage>
      {/*console.log(data)*/}
      <h2>Welcome {data.me.username}</h2>
      <StreamList 
        streams={data.me.streams} 
        handleTrClick={handleViewStream}
        showDelete={(roll === 'ADMIN')}
        handleDelete={handleDelete}
      />
      { (roll === 'ADMIN') ? (
        <FlexRow>
          <FlexPadding>
            <Button onClick={() => {
              props.history.push('/users');
            }}>
              Users
            </Button>
          </FlexPadding>
          <FlexPadding>
            <Button onClick={() => {
              props.history.push('controlrooms');
            }}>
              Control Rooms
            </Button>
          </FlexPadding>
          <FlexPadding>
            <Button 
              onClick={(e) => 
              props.history.push(`/streamcreate/${data.me._id}`)
              }>
              Add Stream Subscription
            </Button>
          </FlexPadding>
        </FlexRow>
      ) : (
        <></>
      )}
    </CenteredPage>
  )
}

export default Home;
