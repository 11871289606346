import { gql } from '@apollo/client';

const SIGN_IN = gql`
  mutation signIn ($username: String!, $password: String!) {
    signIn(username: $username, password: $password) {
      jwt
      roll 
    }
  }
`;


const CREATE_USER = gql`
  mutation createUser ($username: String!, $password: String!, $roll: String!) {
    createUser(username: $username, password: $password, roll: $roll) {
      _id
      username
      streams {
        controlRoom {
          name
          streamName
        }
        subToken
        tokenId
      }
    }
  }
`;

const DELETE_STREAM = gql`
  mutation deleteStream($id: ID!) {
    deleteStream(_id: $id) 
  }
`;

const CREATE_STREAM = gql`
  mutation createStream ($user_id: ID!, $controlRoom_id: ID!, $expiration: Int) {
    createStream(user_id: $user_id, controlRoom_id: $controlRoom_id, expiration: $expiration){
      _id
      user {
        _id
        username
      }
      controlRoom {
        _id
        name
      }
      subToken
      expiresOn
      addedOn
    }
  }
`;

const CREATE_CONTROL_ROOM = gql`
  mutation ($name: String!, $streamName: String!) {
    createControlRoom(name: $name, streamName: $streamName ){
      _id
      name
      streamName
    }
  }
`;

const UPDATE_CONTROL_ROOM_SET = gql`
  mutation ($id: ID!, $name: String!, $streamName: String!){
    updateControlRoomSet(_id: $id, name: $name, streamName: $streamName) {
      _id
      name
      streamName
    }
  }
`;



export {
  CREATE_USER,
  DELETE_STREAM,
  SIGN_IN,
  CREATE_STREAM,
  CREATE_CONTROL_ROOM,
  UPDATE_CONTROL_ROOM_SET
};
