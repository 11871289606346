import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'login',
  initialState: {
    isLoggedIn: (!!localStorage.getItem('production-view-token') && !!localStorage.getItem('production-view-roll')),
    roll: localStorage.getItem('production-view-roll'),
  },
  reducers: {
    login: (state, action) => {
      localStorage.setItem('production-view-token', action.payload.jwt);
      localStorage.setItem('production-view-roll', action.payload.roll);
      state.isLoggedIn = true;
      state.roll= action.payload.roll;
    },
    logout: state => {
      localStorage.removeItem('production-view-token');
      localStorage.removeItem('production-view-roll');
      state.isLoggedIn = false;
      state.roll= null;
    },
  },
});

export const { login, logout } = slice.actions;

export const selectLogin = state => state.login.isLoggedIn;
export const selectRoll = state => state.login.roll;

export default slice.reducer;

