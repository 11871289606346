import styled from 'styled-components';

const FlexRow = styled.div`
  padding: 10px;
  display: flex;   
  justify-content: center;
`;

const FlexPadding = styled.div`
  padding: 10px;
`;

export { FlexPadding };

export default FlexRow;
