import React from 'react';
import styled from 'styled-components';


const FooterBar = styled.footer`
  
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  width: 100%;

  background-color: inherit;
`;


// box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.50);

const Header = () => {
  return (
    <FooterBar>
      <p>Signature Production Group</p>
    </FooterBar>
  );
};

export default Header;

