import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import CenteredPage from '../components/CenteredPage';
import FormColumn from '../components/FormColumn';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import FlexRow, { FlexPadding } from '../components/FlexRow';

import { GET_USERS } from '../gql/query';
import { CREATE_USER } from '../gql/mutation';

const UserCreate = (props) => {
  const [ values, setValues ] = useState();

  const [ createUser ] = useMutation(CREATE_USER, {
    refetchQueries: [{ query: GET_USERS }],
    onCompleted: data => {
      console.log("Created New User");
      props.history.push("/users");
    },
  });

  const onChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  }

  const rolls = [
    { _id: 'ADMIN', name: 'ADMIN' },
    { _id: 'VIEWER', name: 'VIEWER' }
  ];


  return( 
    <CenteredPage>
      <h2>Create User</h2>
      <FormColumn onSubmit={ (e) => {
        e.preventDefault();
        createUser({
          variables: {
            ...values
          }
        })}}>
        <Dropdown 
          title={"Select Roll"}
          options={rolls}
          name="roll"
          onChange={onChange}
        />
        <label htmlFor="username">Username:</label>
        <input
          required
          type="text"
          id="username"
          name="username"
          placeholder="username"
          onChange={onChange}
        />
        <label htmlFor="password">Password:</label>
        <input
          required
          type="text"
          id="password"
          name="password"
          placeholder="password"
          onChange={onChange}
        />
        <FlexRow>
          <FlexPadding>
            <Button 
              type='reset'
              onClick={() => props.history.goBack()}
            >Cancel</Button>
          </FlexPadding>
          <FlexPadding>
            <Button type="submit">Submit</Button>
          </FlexPadding>
        </FlexRow>
      </FormColumn>
    </CenteredPage>
  )
};

export default UserCreate;
