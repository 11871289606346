import Pages from './pages';
import GlobalStyles from './GlobalStyles';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
// import { onError } from "@apollo/client/link/error";

const uri = process.env.REACT_APP_API_URI;
console.log(uri);
const cache = new InMemoryCache();
const httpLink = createHttpLink({ uri });
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: localStorage.getItem('production-view-token') || ''
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: true,
});

function App() {
  return (
    <ApolloProvider client={client}>
      <GlobalStyles />
      <Pages />
    </ApolloProvider>
  );
}

export default App;
