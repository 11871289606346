import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import {
  logout,
  selectLogin
} from '../redux/loginSlice';

import ButtonLink from './ButtonLink';

const Headerbar = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  
  background-color: #222222;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.50);
`;


const Header = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogin);
  const client = useApolloClient();

  return (
    <Headerbar>
      {isLoggedIn ? (
        <ButtonLink
          onClick={ () => {
            props.history.push('/home');
          }}>
        Home
        </ButtonLink>
      ) : (
        <>Home</>
      )}
      <h1>Production View</h1>
      {isLoggedIn ? (
        <ButtonLink onClick={ () => {
          dispatch(logout());
          props.history.push('/');
          // Get rid of the race condition...
          // still not sure where it is coming from.
          setTimeout(()=>{
            client.resetStore();
          }, 0);
        }}>
        Logout
        </ButtonLink>
      ) : (
        <ButtonLink>Login</ButtonLink>
      )}
    </Headerbar>
  );
};

export default withRouter(Header);

