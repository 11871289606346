import React from 'react';
import CenteredPage from '../components/CenteredPage';

const LoadingError = (props) => {
  return (
    <React.Fragment>
      {props.loading ? (
        <CenteredPage>
          <h1>Loading...</h1>
        </CenteredPage>
      ) : (<></>)}
      {props.error ? (
        <CenteredPage>
          {console.log(props.error)}
          <h1>Error...</h1>
        </CenteredPage>
      ) : (<></>)}

    </React.Fragment>
  )
}

export default LoadingError;
