import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectLogin,
  selectRoll
} from '../redux/loginSlice';

import Layout from '../components/Layout';

import Root from './Root';
import Home from './Home';
import Stream from './Stream';
import StreamCreate from './StreamCreate';
import Users from './Users';
import User from './User';
import UserCreate from './UserCreate';
import ControlRooms from './ControlRooms';
import ControlRoomEdit from './ControlRoomEdit';
import ControlRoomCreate from './ControlRoomCreate';


const pages = () => {
  return (
    <Router>
      <Layout>
        <Route exact path='/' component={Root} />
        <PrivateRoute rolls={['ADMIN', 'VIEWER']} path='/home' component={Home} />
        <PrivateRoute rolls={['ADMIN', 'VIEWER']} path='/stream/:streamName' component={Stream} />
        <PrivateRoute rolls={['ADMIN']} path='/users' component={Users} />
        <PrivateRoute rolls={['ADMIN']} path='/user/:id' component={User} />
        <PrivateRoute rolls={['ADMIN']} path='/usercreate' component={UserCreate} />
        <PrivateRoute rolls={['ADMIN']} path='/controlrooms' component={ControlRooms} />
        <PrivateRoute rolls={['ADMIN']} path='/controlroomedit/:id' component={ControlRoomEdit} />
        <PrivateRoute rolls={['ADMIN']} path='/controlroomcreate' component={ControlRoomCreate} />
        <PrivateRoute rolls={['ADMIN']} path='/streamcreate/:id' component={StreamCreate} />
      </Layout>
    </Router>
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(selectLogin);
  const roll = useSelector(selectRoll);
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={props =>
            (isLoggedIn === true && (rest.rolls.includes(roll) || !rest.rolls)) ? (
            <React.Fragment>
              <Component {...props} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Redirect
                to={{
                  pathname: '/',
                  state: { from : props.location }
                }}
              />
            </React.Fragment>
          )
        }
      />
    </React.Fragment>
  );
};


export default pages;

