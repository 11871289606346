import styled from 'styled-components';


const DDWrapper = styled.div`
  position: relative;
  min-width: 220px;
  background: #1A1A1A;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: 100;

  
`;

const DDHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding 10px;
  border-bottom: 3px solid #333333;

`;

const DDHeaderTitle = styled.div`
  display: flex;
  justify-content: space-around;
  
  :hover {
    color: #A5A5A5;
  }
`;

const DDList = styled.div`
  top: 44px;
  width: 100%;
  position: absolute;
  z-index: 10;
  background: #1A1A1A;

`;

const DDListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding 5px;
  border-bottom: 3px solid #222222;

  :hover {
    color: #A5A5A5;
  }

`;


export {
  DDWrapper,
  DDHeader,
  DDHeaderTitle,
  DDList,
  DDListItem,
}

