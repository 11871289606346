import React, { useEffect, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import CenteredPage from '../components/CenteredPage';
import FormColumn from '../components/FormColumn';
import Button from '../components/Button';
import FlexRow, { FlexPadding } from '../components/FlexRow';
import LoadingError from '../components/LoadingError';
import { GET_CONTROL_ROOM, GET_CONTROL_ROOMS } from '../gql/query';
import { UPDATE_CONTROL_ROOM_SET } from '../gql/mutation';



const ControlRoomEdit= (props) => {
  const [ values, setValues ] = useState({
    _id: '',
    name: '',
    streamName: '',
  });
  const { data, loading, error } = useQuery(GET_CONTROL_ROOM, {
    variables: {
      id: props.match.params.id
    }
  });
  const [updateControlRoomSet ] = useMutation(UPDATE_CONTROL_ROOM_SET, {
    refetchQueries: [{ query: GET_CONTROL_ROOMS }],
    onCompleted: data => {
      props.history.goBack();
    },
  });
  

  // Initialize the values of the form
  // Don't forget the value property on input
  useEffect( () => {
    if (data) {
      setValues({
        id: data.controlRoom._id,
        name: data.controlRoom.name,
        streamName: data.controlRoom.streamName
      });
    }
  }, [data])

  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }


  if (loading || error) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  return( 
    <CenteredPage>
      {console.log(data)}
      <h2>Edit Control Room</h2>
      <FormColumn
        onSubmit={ e => {
          e.preventDefault();
          updateControlRoomSet({
            variables: {
              ...values
            }
        })}}
      >
        <label htmlFor="Name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={values.name}
          onChange={onChange}
        />
        <label htmlFor="Stream Name">Stream Name</label>
        <input
          type="text"
          id="streamName"
          name="streamName"
          value={values.streamName}
          onChange={onChange}
        />
        <FlexRow>
          <FlexPadding>
            <Button 
              type='reset'
              onClick={() => props.history.goBack()}
            >Cancel</Button>
          </FlexPadding>
          <FlexPadding>
            <Button type="submit">Submit</Button>
          </FlexPadding>
        </FlexRow>
      </FormColumn>
    </CenteredPage>
  )
};

export default ControlRoomEdit;
