import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Table, Tbody, Thead, Tr, Th, Td, } from '../components/Table';
import CenteredPage from '../components/CenteredPage';
import LoadingError from '../components/LoadingError';
import { GET_CONTROL_ROOMS } from '../gql/query';
import FlexRow, { FlexPadding } from '../components/FlexRow';
import Button from '../components/Button';
import { DeleteTrashcan } from '../components/Icons';


const DELETE_CONTROL_ROOM = gql`
  mutation deleteControlRoom ($id: ID!) {
    deleteControlRoom(_id: $id)
  }
`;

const ControlRooms = (props) => {
  const { data, loading, error } = useQuery(GET_CONTROL_ROOMS);
  const [ deleteControlRoom ] = useMutation(DELETE_CONTROL_ROOM, {
    refetchQueries: [{ query: GET_CONTROL_ROOMS }]
  });

  if (loading || error) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  const handleDelete = (e, room) => {
    e.stopPropagation();
    console.log(room);

    deleteControlRoom({
      variables: {
        id: room._id
      }
    });

    // props.handleDelete(e, stream);
  }

  return( 
    <CenteredPage>
      {/*console.log(data)*/}
      <h2>Control Rooms</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Stream Name</Th>
            <Th>
              <DeleteTrashcan />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.controlRooms.map(room => (
            <Tr 
              key={room._id}
              onClick={() => {
                props.history.push(`controlroomedit/${room._id}`)
              }}
            >
              <Td>{room.name}</Td>
              <Td>{room.streamName}</Td>
              <Td>
                <DeleteTrashcan 
                  onClick={(e) => handleDelete(e, room)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <FlexRow>
        <FlexPadding>
          <Button
            onClick={() => {
              props.history.push("/controlroomcreate");
            }}
          >
            Add Control Room
          </Button>
        </FlexPadding>
      </FlexRow>
    </CenteredPage>
  )
};

export default ControlRooms;
