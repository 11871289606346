import React, { useEffect, useState } from 'react';

import CenteredPage from '../components/CenteredPage';

import { updateMillicastAuth, getICEServers, connect } from '../millicastConnection';


const Stream = (props) => {
  const [ videoSrc, setVideoSrc ] = useState('');
  
  const streamName = props.match.params.streamName;
  const accountId = process.env.REACT_APP_ACCOUNT_ID;   // Later put this in env.
  const subToken = localStorage.getItem("SubToken");

  const apiPath  = 'https://director.millicast.com/api/director/subscribe';
  const turnUrl  = 'https://turn.millicast.com/webrtc/_turn';

  useEffect( () => {
    const doIt = async () => {
      // Get the ice servers
      const iceServers = await getICEServers({ turnUrl });
      console.log(iceServers);

      // get the jwt and the url from Millicast
      const { jwt, url } = await updateMillicastAuth({ streamName, apiPath, accountId, subToken });
      console.log(jwt);
      console.log(url);

      // Now connect to the stream
      const stream = await connect({ url, iceServers, jwt, accountId });

      // Update the state stream
      setVideoSrc(stream);
    }
    doIt();
  }, [accountId,streamName, subToken]);

  // Set the video to play on a click
  useEffect( () => {
    let v = document.getElementsByTagName('video')[0];
    if (v) {
      v.addEventListener("click", evt => {
        v.play();
      });
    }
  }, []);

  // Attach the video track to the srcObject
  useEffect( () => {
    if (videoSrc) {
      let vidWin = document.getElementsByTagName('video')[0];
      if (vidWin) {
        vidWin.srcObject = videoSrc;
        vidWin.controls = true;
      }
    }

  }, [videoSrc]);


  return(
    <CenteredPage>
      <video 
        muted 
        autoPlay 
        playsInline 
        controls={true}
      >
      </video>
    </CenteredPage>
  );
};

export default Stream;

