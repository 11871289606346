import React, { useState } from 'react';

import { useMutation } from '@apollo/client';
import CenteredPage from '../components/CenteredPage';
import FormColumn from '../components/FormColumn';
import Button from '../components/Button';
import FlexRow, { FlexPadding } from '../components/FlexRow';
import { GET_CONTROL_ROOMS } from '../gql/query';
import { CREATE_CONTROL_ROOM } from '../gql/mutation';


const ControlRoomCreate = (props) => {
  const [ values, setValues ] = useState();
  const [ createControlRoom ] = useMutation(CREATE_CONTROL_ROOM, {
    refetchQueries: [{ query: GET_CONTROL_ROOMS }],
    onCompleted: data => {
      props.history.goBack();
    }
  });

  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  return( 
    <CenteredPage>
      <h2>Create Control Room</h2>
      <FormColumn
        onSubmit={ e => {
          e.preventDefault();
          createControlRoom({
            variables: {
              ...values
            }
          });
        }}
      >
        <label htmlFor="Name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Control Room"
          onChange={onChange}
        />
        <label htmlFor="Stream Name">Stream Name</label>
        <input
          type="text"
          id="streamName"
          name="streamName"
          placeholder="abcdefgh"
          onChange={onChange}
        />
        <FlexRow>
          <FlexPadding>
            <Button 
              type='reset'
              onClick={() => props.history.goBack()}
            >Cancel</Button>
          </FlexPadding>
          <FlexPadding>
            <Button type="submit">Submit</Button>
          </FlexPadding>
        </FlexRow>
      </FormColumn>
    </CenteredPage>
  )
};

export default ControlRoomCreate;
