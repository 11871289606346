// Most things are like the SKD example, but everything is wrapped in promises.
const updateMillicastAuth = ({ apiPath, streamName, accountId, subToken }) => {
  return new Promise((resolve, reject) => {
    console.log('updateMillicastAuth at: ' + apiPath + ' for:', streamName, ' accountId:', accountId);
    let xhr                = new XMLHttpRequest();
    xhr.onreadystatechange = function (evt) {
      if (xhr.readyState === 4) {
        let res = JSON.parse(xhr.responseText);
        console.log('res: ', res);
        console.log('status:', xhr.status, ' response: ', xhr.responseText);
        switch (xhr.status) {
          case 200:
            resolve({ jwt: res.data.jwt, url: res.data.urls[0] });
            break;
          default:
            reject(res);
        }
      }
    }
    xhr.open("POST", apiPath, true);
    xhr.setRequestHeader("Authorization", `Bearer ${subToken}`);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify({streamAccountId: accountId, streamName: streamName}));
  });
}

// More promise wrapping
const getICEServers = ({ turnUrl }) => {
  console.log("getICEServers");
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function (evt) {
      if (xhr.readyState === 4) {
        let res = JSON.parse(xhr.responseText), a;
        console.log('getICEServers::status:', xhr.status, ' response: ', xhr.responseText);
        switch (xhr.status) {
          case 200:
            //returns array.
            if (res.s !== 'ok') {
              a = [];
              //failed to get ice servers, resolve anyway to connect w/ out.
              resolve(a);
              return
            }
            let list = res.v.iceServers;
            a = [];
            //call returns old format, this updates URL to URLS in credentials path.
            list.forEach(cred => {
              let v = cred.url;
              if (!!v) {
                cred.urls = v;
                delete cred.url;
              }
              a.push(cred);
              //console.log('cred:',cred);
            });
            console.log('ice: ', a);
            resolve(a);
            break;
          default:
            a = [];
            //reject(xhr.responseText);
            //failed to get ice servers, resolve anyway to connect w/ out.
            resolve(a);
            break;
        }
      }
    }
    xhr.open("PUT", turnUrl, true);
    xhr.send();
  })
}

// And still more promise wrapping. This one is still a little odd becuase there is a mix 
// of then/catch and async...eventually it might be a good idea to move all to async/await.
const connect = ({ url, iceServers, jwt, accountId }) => {
  return new Promise((resolve, reject) => {
    console.log('connecting to: ', url);
    // create Peer connection object
    let conf = {
      iceServers: iceServers,
      // sdpSemantis : "unified-plan",
      rtcpMuxPolicy: "require",
      bundlePolicy: "max-bundle"
    };
    console.log('config: ', conf);
    let pc = new RTCPeerConnection(conf);
    // Listen for track once it starts playing.
    pc.ontrack = (event) => {
      console.log("pc:onAddStream", event);
      resolve(event.streams[0]);
    };

    console.log('connecting to: ', url + '?token=' + jwt); // token
    // connect with Websockets for handshake to media server.
    let ws = new WebSocket(url + '?token=' + jwt);
    ws.onopen = () => {
      // connect to our media server via WebRTC
      console.log('ws::onopen');
      // Here's a bunch of random transeaver stuff....seems to be optional and maybe not supported

      // create a WebRTC offer to send to the media server
      let offer = pc.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      }).then(desc => {
        console.log('createOffer Success!');
        // set the local description and send offer to medial server via ws
        pc.setLocalDescription(desc)
          .then(() => {
            console.log('setLocalDescription Success!');
            // set the required information for the media server
            let data = {
              streamId: accountId,
              sdp: desc.sdp
            }
            // create payload
            let payload = {
              type: "cmd",
              transId: 0,
              name: 'view',
              data: data
            }
            console.log('send ', payload);
            ws.send(JSON.stringify(payload));
          })
          .catch(e => {
            console.log('setLocalDescription Faild: ', e);
            reject(e);
          })
      }).catch(e => {
        console.log("createOffer Failed: ", e);
        reject(e);
      });

      ws.addEventListener('message', evt => {
        console.log('ws::message', evt);
        let msg = JSON.parse(evt.data);
        switch (msg.type) {
          // Handle counter response coming from the Media Server.
          case "response":
            let data = msg.data;

            let remotesdp = data.sdp;

            /* handle older versions of Safari */
            if (remotesdp && remotesdp.indexOf('\na=extmap-allow-mixed') !== -1) {
              remotesdp = remotesdp.split('\n').filter((line) => {
                return line.trim() !== 'a=extmap-allow-mixed';
              }).join('\n');
              console.log('trimmed a=extmap-allow-mixed - sdp \n', remotesdp);
            }
            let answer = new RTCSessionDescription({
              type: 'answer',
              sdp: remotesdp
            });
            pc.setRemoteDescription(answer)
              .then( d => {
                console.log('setRemoteDescription Success!');
              })
              .catch( e => {
                console.log('setRemoteDescription failed: ', e);
              });
            break;
          default:
            console.log("here's a default case....stupid error");
          break;
        }
      })
    }
  });
}



export { updateMillicastAuth, getICEServers, connect };
