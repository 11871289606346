import { 
  AiOutlineCheck,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineDelete,
} from 'react-icons/ai';
import styled from 'styled-components';

const DeleteTrashcan = styled(AiOutlineDelete)`
  padding: 0px;
  margin: 0px;
  font-size: 1.5em;
  vertical-align: middle;
  
  :hover {
    color: #A5A5A5;
  }
`;

const Check = styled(AiOutlineCheck)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;
`;

const ArrowUp = styled(AiOutlineArrowUp)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;
`;
const ArrowDown = styled(AiOutlineArrowDown)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;
`;

export {
  Check,
  ArrowDown,
  ArrowUp,
  DeleteTrashcan
}

