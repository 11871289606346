import React from 'react';
import { useQuery } from '@apollo/client';

import { Table, Tbody, Thead, Tr, Th, Td, } from '../components/Table';
import CenteredPage from '../components/CenteredPage';
import Button from '../components/Button';
import FlexRow, { FlexPadding } from '../components/FlexRow';
import LoadingError from '../components/LoadingError';

import { GET_USERS } from '../gql/query';


const Users = (props) => {
  const { data, error, loading } = useQuery(GET_USERS);

  if (loading || error) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  return( 
    <CenteredPage>
      {/*console.log(data)*/}
      <h2>Users</h2>
      <Table>
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Roll</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.users.map( user => {
            return(
              <Tr 
                key={user._id} 
                onClick={ () => {
                  props.history.push(`/user/${user._id}`);
                }}
              >
                <Td>{user.username}</Td>
                <Td>{user.roll}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <FlexRow>
        <FlexPadding>
          <Button 
            onClick={(e) => props.history.push('usercreate')}
          >
            Add User 
          </Button>
        </FlexPadding>
      </FlexRow>


    </CenteredPage>
  )
};

export default Users;
