import React from 'react';
import { Table, Tbody, Thead, Tr, Th, Td, } from './Table';
import { DeleteTrashcan } from './Icons';


const StreamList = (props) => {
  const streams = props.streams;

  const handleDelete = (e, stream) => {
    e.stopPropagation();

    props.handleDelete(e, stream);
  }

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Control Room</Th>
          <Th>Stream Name</Th>
          <Th>Expires On</Th>
          <Th>Created On</Th>
          {props.showDelete && (
            <Th>
              <DeleteTrashcan />
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {streams.map( stream => {
          return (
            <Tr 
              key={stream._id} 
              onClick={(e) => props.handleTrClick(e, stream)}>
              <Td>{stream.controlRoom.name}</Td>
              <Td>{stream.controlRoom.streamName}</Td>
              <Td>{stream.expiresOn || 'Never'}</Td>
              <Td>{stream.addedOn}</Td>
              {props.showDelete && (
              <Td>
                <DeleteTrashcan onClick={(e) => handleDelete(e, stream)}/>
              </Td>
              )}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default StreamList;
